import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import 'leaflet/dist/leaflet.css';

Vue.use(VueCookies,
  { 
    expires: '30d'
  }
)

Vue.use(VueAxios,axios)

axios.defaults.baseURL='http://webservices-avanzainmuebles.xyz/api/'
axios.defaults.headers.common = {'Authorization': `Bearer eyJpZCI6MTksInNlY3JldCI6Im5yM1lZRHdpeWMwWFpsUk5MZ0FxZGpsMk1oS0VSVmdZcjhja1l2N3VUS1ZNcHBOY2s4aUdlUEJhWGNVaSJ9`}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
