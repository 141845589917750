<template>
  <div id="app" class="wrapper"> 
    <div class="cookie-consent shadow-lg" v-if="!areCookiesAccepted">
      <div class="container mt-4">
        <h4>Utilizamos cookies</h4>
        <p>
          Este sitio web utiliza cookies propias y de terceros, el uso del mismo implica la aceptación de la <router-link to="politica-de-cookies">Política de cookies</router-link>
        </p>
        <button class="btn btn orange-button mb-4" @click="acceptCookies">Aceptar</button>
      </div>
    </div>
    <div class="preloader"></div>
      <!-- Main Header Nav -->
      <header v-if="['Home', 'Sell','Buy'].includes($route.name)" class="header-nav menu_style_home_one home4_style navbar-scrolltofixed stricky main-menu">
        <div class="container"> 
          <!-- Ace Responsive Menu -->
          <nav> 
            <!-- Menu Toggle btn-->
            <div class="menu-toggle">
              <img class="nav_logo_img img-fluid" src="./assets/static/avanza.png" alt="avanza.png">
              <button type="button" id="menu-btn">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
            </div>
            <router-link to="/" class="navbar_brand float-left dn-md">
              <img class="logo1 img-fluid" src="./assets/static/avanza-white.png" alt="avanza-white.png">
              <img class="logo2 img-fluid" src="./assets/static/avanza.png" alt="avanza.png">
            </router-link> 
            <!-- Responsive Menu Structure-->
            <ul id="respMenu" class="ace-responsive-menu text-right" data-menu-style="horizontal">
              <li><router-link to="/">Inicio</router-link></li>
              <li><a href="/listado-inmuebles?operacion=venta">Venta</a></li>
              <li><a href="/listado-inmuebles?operacion=alquiler">Alquiler</a></li>
              <li><a href="blog.avanzainmuebles.com">Noticias</a></li>
            </ul>
          </nav>
        </div>
      </header>
      <header v-else class="header-nav menu_style_home_one style2 menu_boxshadow navbar-scrolltofixed stricky main-menu ">
        <div class="container p0">
          <!-- Ace Responsive Menu -->
          <nav>
            <!-- Menu Toggle btn-->
            <div class="menu-toggle"><img class="nav_logo_img img-fluid" src="./assets/static/avanza-white.png" alt="avanza-white.png">
              <button type="button" id="menu-btn"><span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span></button>
            </div>
            <router-link to="/" class="navbar_brand float-left dn-md"><img class="logo1 img-fluid" src="./assets/static/avanza.png" alt="avanza.png"><img class="logo2 img-fluid" src="./assets/static/avanza.png" alt="avanza.png"></router-link>
            <!-- Responsive Menu Structure-->
            <ul id="respMenu" class="ace-responsive-menu text-right" data-menu-style="horizontal">
              <li><router-link to="/">Inicio</router-link></li>
              <li><a href="/listado-inmuebles?operacion=venta"><span class="title">Venta</span></a></li>
              <li><a href="/listado-inmuebles?operacion=alquiler"><span class="title">Alquiler</span></a></li>
              <li><a href="blog.avanzainmuebles.com">Noticias</a></li>
            </ul>
          </nav>
        </div>
      </header>

      <!-- Main Header Nav For Mobile -->
      <div id="page" class="stylehome1" v-if="!(['Home', 'Sell','Buy'].includes($route.name))">
        <div class="mobile-menu">
          <div class="header stylehome1">
            <div class="main_logo_home2 text-center"><img style="margin-top:25px !important" class="nav_logo_img img-fluid mt10" src="./assets/static/avanza-white.png" alt="header-logo.svg"></div>
            <ul class="menu_bar_home2">
              <li class="list-inline-item"><a class="custom_search_with_menu_trigger msearch_icon" href="#"></a></li>
              <li class="list-inline-item"><!--<a class="muser_icon" href="#" data-toggle="modal" data-target="#logInModal"><span class="flaticon-user"></span></a>--></li>
              <li class="list-inline-item"><a class="menubar" href="#menu"><span></span></a></li>
            </ul>
          </div>
        </div>
        <!-- /.mobile-menu -->
        
      </div>
      <nav id="menu" class="stylehome1">
          <ul>
            <li><a href="/">Inicio</a></li>
            <li><a href="/listado-inmuebles?operacion=venta">Venta</a></li>
            <li><a href="/listado-inmuebles?operacion=alquiler">Alquiler</a></li>
            <li><a href="blog.avanzainmuebles.com">Noticias</a></li>
          </ul>
        </nav>
    
    <div id="page">
      <router-view/>
    </div>

    <div class="py-5" style="background-color:#222222">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-3 mb-3">
            <h5 class="text-white" style="font-weight:700;">Contacto</h5>
            <ul>
              <li><a class="text-white" href="mailto:info@avanzainmuebles.com">info@avanzainmuebles.com</a></li>
              <li><a class="text-white" href="tel:34966119554">+34 966 119 554</a></li>
              <li class="text-white">Av. Constitución, 104 Entlo. - B Villena 03400 (Alicante)</li>
            </ul>
          </div>
          <div class="col-12 col-lg-3 mb-3">
            <h5 class="text-white" style="font-weight:700;">Avanza</h5>
            <ul>
              <li><router-link class="text-white" to="vender-alquilar">Anuncia tu inmueble</router-link></li>
              <li><router-link class="text-white" to="encontrar-un-hogar">Encuentra tu hogar ideal</router-link></li>
              <li><a class="text-white" href="blog.avanzainmuebles.com">Noticias</a></li>
            </ul>
          </div>
          <div class="col-12 col-lg-3 mb-3">
            <h5 class="text-white" style="font-weight:700;">Legal</h5>
            <ul>
              <li><router-link class="text-white" to="politica-de-privacidad">Política de privacidad</router-link></li>
              <li><router-link class="text-white" to="politica-de-cookies">Política de Cookies</router-link></li>
              <li><router-link class="text-white" to="aviso-legal">Avsio Legal</router-link></li>
            </ul>
          </div>
          <div class="col-12 col-lg-3 mb-3">
            <h5 class="text-white" style="font-weight:700;">Síguenos</h5>
            <div class="row">
              <a href="https://www.facebook.com/AvanzaInmuebles"><i class="fa fa-facebook text-white mr-3"></i></a>
              <a href="https://www.instagram.com/avanzainmobiliaria"><i class="fa fa-instagram text-white"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
/*
<style>
  .cookie-consent{
    background-color: white;
    width:100%;
    position:fixed;
    bottom:0;
    left:0;
    z-index:9999;
    border-radius:30px 30px 0 0;
    overflow-wrap: normal;
  }
  body{ 
    zoom:85%; 
  } 
  .form-control,.custom-select{
    height: 40px !important;
  }
  .logo1,.logo2,.nav_logo_img{
    height: 20px !important;
    margin-top: 15px;
  }
</style>
<script>

export default {

  data(){
    return {
      avanza_accepted_cookies:false
    }
  },
  created(){
    if($cookies.isKey("avanza_accepted_cookies")){
      this.avanza_accepted_cookies=$cookies.get("avanza_accepted_cookies")
    }
  },
  methods:{
    acceptCookies(){
      $cookies.set("avanza_accepted_cookies",true);
      this.avanza_accepted_cookies = true;
    }
  },
  computed:{
    areCookiesAccepted(){
      return this.avanza_accepted_cookies;
    }
  }
}
</script>

