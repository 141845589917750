<template>
    <div :class="`col-lg-${colLg} col-xl-${colXl}`">
            
            <div class="feat_property">
                <div v-if="error" class="alert alart_style_three alert-dismissible fade show" role="alert" style="position:absolute;z-index:2;top:5px;left:5px;right:5px;"> {{error_msg}}
                    <button type="button" class="close"  @click="error=false"> <span aria-hidden="true">×</span> </button>
                </div>
                <router-link :to="`/inmueble/${idProp}`">
                <div class="thumb">
                    <img class="img-whp" :src="image" alt="lg10.jpg" style="height:200px!important;width:100%!important; object-fit:cover;">
                    <div class="thmb_cntnt">
                    <ul class="tag2 mb0">
                        <li class="list-inline-item"><a href="#">{{this.operaciones[operacion]}}</a></li>
                    </ul>
                    </div>
                    <div class="thmb_cntnt2">
                    <ul class="listing_gallery mb0">
                        <li class="list-inline-item"><a class="text-white" href="#"><span class="flaticon-photo-camera mr5"></span> {{numFotos}}</a></li>
                    </ul>
                    </div>
                </div>
                </router-link>
                <div class="details">
                    <router-link :to="`/inmueble/${idProp}`">
                    <div class="tc_content">
                    <h4><a href="">{{tipo}} en {{ciudad}} situado en la zona de {{zona}}</a></h4>
                    <p>{{ciudad}} - {{zona}}</p>
                    <ul class="prop_details mb0">
                        <li class="list-inline-item"><a href="#"><span ><b>REF: </b></span> <br>{{referencia}}</a></li>
                        <li class="list-inline-item" v-if="dorm>0"><a href="#"><span class="flaticon-bed"></span> <br>{{dorm}} Dormitorios</a></li>
                        <li class="list-inline-item" v-if="banyos>0"><a href="#"><span class="flaticon-bath"></span> <br>{{banyos}} Baños</a></li>
                        <li class="list-inline-item" v-if="sup>0"><a href="#"><span class="flaticon-ruler"></span> <br>{{sup}} &#13217;</a></li>
                    </ul>
                    </div>
                    </router-link>
                    <div class="fp_footer">
                    <ul class="fp_meta float-left mb0">
                        <li class="list-inline-item">
                        <a href="#">
                            <small><del class="body-color text-danger" v-if="precioCom">{{Math.ceil(precioCom)}}€</del></small><br>
                            <span class="heading-color fw600" v-if="precioInmo">{{Math.ceil(precioInmo).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</span>
                            <span v-if="precioInmo && precioAlq"> - </span>
                            <span class="heading-color fw600" v-if="precioAlq">{{Math.ceil(precioAlq).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} € / MES</span>
                            <span class="heading-color fw600" v-if="precioAlq==0 && precioInmo==0">A consultar</span>
                        </a>
                        </li>
                    </ul>
                    <ul class="fp_meta float-right mb0" >
                        <li v-if="!inFavorites" class="list-inline-item" @click="addToFavorites()" style="height:25px;width:25px;border-radius:100%;padding-left:6px; padding-top:2px">  <span class="flaticon-heart-shape-outline"></span></li>
                        <li v-else class="list-inline-item" @click="quitFromFavorites()" style="background-color:#ECC8C5!important;height:25px;width:25px;border-radius:100%;padding-left:6px; padding-top:2px"><span class="flaticon-heart-shape-outline text-danger"></span></li>
                    </ul>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>

export default {
  name: 'Property',
  props: {
    idProp: Number,
    image: String,
    tipo: String,
    dorm: Number,
    banyos: Number,
    sup: Number,
    referencia: String,
    precioInmo:Number,
    precioAlq:Number,
    precioCom:Number,
    ciudad:String,
    zona:String,
    numFotos:Number,
    operacion:Number,
    tipoAlq:String,
    colLg:Number,
    colXl:Number
  },
  data(){
    return{
        operaciones:{'1':'VENDER','2':'ALQUILAR','4':'VENDER - ALQUILAR'},
        inFavorites:false,
        error_msg:null,
        error:false
    }
  },
  created(){
    this.inFavorites = this.isInFavorites(this.idProp)
  },
  methods:{
    addToFavorites(){
        let timestamp = new Date()
        let props_json = {
            "idProp":this.idProp,
            "image":this.image,
            "tipo":this.tipo,
            "dorm":this.dorm,
            "banyos":this.banyos,
            "sup":this.sup,
            "referencia":this.referencia,
            "precioInmo":this.precioInmo,
            "precioAlq":this.precioAlq,
            "precioCom":this.precioCom,
            "ciudad":this.ciudad,
            "zona":this.zona,
            "numFotos":this.numFotos,
            "operacion":this.operacion,
            "tipoAlq":this.tipoAlq,
            "timestamp":timestamp.toISOString()
        }

        let favs = $cookies.isKey("avanza_favorite_properties") ? $cookies.get("avanza_favorite_properties") : [] ;
        if(favs.length < 8){
            favs.push(props_json)
            let new_favs = JSON.stringify(favs)
            $cookies.set("avanza_favorite_properties",new_favs);
            this.inFavorites=true;
        }else{
            this.error_msg="No se pueden seleccionar mas de 8 propiedades favoritas"
            this.error=true
        }
        
    },
    quitFromFavorites(){
        let favs = $cookies.isKey("avanza_favorite_properties") ? $cookies.get("avanza_favorite_properties") : [] ;
        favs = favs.filter(prop => prop.idProp != this.idProp)
        let new_favs = JSON.stringify(favs)
        $cookies.set("avanza_favorite_properties",new_favs);
        this.inFavorites=false;
    },
    isInFavorites(id){
        if($cookies.isKey("avanza_favorite_properties")){
            let favs = $cookies.get("avanza_favorite_properties").map(x => x.idProp)
            console.log(favs)
            if(favs.includes(id)){
                return true;
            }
        }
        return false;
    }
  }
}
</script>